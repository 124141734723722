enum Events {
  READY = 'ready',
  FOCUS = 'focus',
  BLUR = 'blur',
  CHANGE = 'change',
  /** this event is only invoked if the validation status of a field changed rather than if something changed more broadly*/
  VALIDITY_CHANGE = 'validity_change',
  CARD_TOKENIZED = 'card_tokenized',
  NEXT_ACTION_BEGUN = 'next_action_begun',
}

export default Events
