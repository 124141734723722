import * as React from 'react'
import CheckoutConfiguration from '../../models/CheckoutConfiguration'
// @ts-ignore
import config from '../../../../../config'
import { PayFrameHolder, PulsingDot } from './styled'
import { useEffect, useState } from 'react'
// @ts-ignore
import CustomIframe from '../../../../common/components/CustomIframe'

interface Props {
  configuration: CheckoutConfiguration
  onCancel?: () => void
  onSuccess?: (token: any) => void
}

const CheckoutIFrame = ({ configuration, ...props }: Props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => setVisible(true), 100)
  }, [])

  const content = `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
            body, html {
                margin: 0;
                background: transparent;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", 
                "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                "Fira Sans", "Droid Sans", "Helvetica Neue",serif;
                height: 100%;
                overflow: auto;
                display: block;
            }
        </style>         
    </head>
    <body>
        <div id="root"></div>
        <!-- We are going to import ourselves again and then use this library to do heavy lifting again inside the iFrame.
            If should effectively load from cache and as such the loading of the subpage should be near instant -->
        <script src="${config.sdk.PUBLIC_URL}"></script>
        <script>
            // Load our configuration into our iFrame
            var configuration = ${JSON.stringify(configuration)}
            BlackbirdSDK__injectPopupApp(configuration, '#root')
            // invoke our global window method to setup the iFrame related components
        </script>
    </body>
    </html>`

  const onSuccess = (token: any) => {
    setVisible(false)
    setTimeout(() => {
      props.onSuccess?.(token)
    }, 500)
  }

  const onCancel = () => {
    setVisible(false)
    setTimeout(() => {
      props.onCancel?.()
    }, 500)
  }

  return (
    <PayFrameHolder visible={visible}>
      <PulsingDot />
      <CustomIframe
        id={'CheckoutIframe'}
        html={content}
        onFrameCreated={(frame: HTMLIFrameElement) => {
          //@ts-ignore
          frame.contentWindow.onCancel = onCancel
          //@ts-ignore
          frame.contentWindow.onSuccess = onSuccess
        }}
      />
    </PayFrameHolder>
  )
}

export default CheckoutIFrame
