import React from 'react'

const CvvTooltip = () => {
  return (
    <div className="yc-info-icon-container">
      <InfoIcon />
      <div className="yc-info-tooltip-container">
        <div className="yc-info-tooltip-content">
          <div className="yc-info-tooltip-content-section">
            <div className="yc-info-tooltip-content-title">
              It is a 3- or 4-digit code usually found on your card.
            </div>
          </div>
        </div>
        <div className="yc-info-tooltip-corner" />
      </div>
    </div>
  )
}

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="7.5" cy="4" r="1" fill="#262626" fillRule="nonzero" />
      <circle
        cx="7.5"
        cy="7.5"
        r="7"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 11.5v-4"
      />
    </g>
  </svg>
)

export default CvvTooltip
