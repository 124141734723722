import config from '../../../../../config'

/** Create an iframe that will be used to capture the device fingerprint*/
export const insertIframe = (identifier, accessToken, bin) => {
  const iframe = document.createElement('iframe')
  iframe.id = 'fpcIFrame'
  iframe.name = 'fpcIFrame'
  iframe.style.display = 'none'
  iframe.style.width = '100%'
  iframe.style.height = '100%'
  iframe.style.borderWidth = '0'
  document.body.appendChild(iframe)
  const form = document.createElement('form')
  form.id = 'fpcForm'
  form.method = 'fpcForm'
  form.method = 'post'
  form.target = 'fpcIFrame'
  form.action = config.cardinal.DEVICE_FINGERPRINT_URL
  const jwtInput = document.createElement('input')
  jwtInput.type = 'hidden'
  jwtInput.name = 'JWT'
  jwtInput.value = accessToken
  const binInput = document.createElement('input')
  binInput.type = 'hidden'
  binInput.name = 'Bin'
  binInput.value = bin
  form.appendChild(jwtInput)
  form.appendChild(binInput)
  document.body.appendChild(form)
  return form
}
