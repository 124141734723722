// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import segmentPlugin from '@analytics/segment'
import Analytics from 'analytics'
// @ts-ignore
import config from '../../../../config'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Segment {
  const analytics = Analytics({
    app: 'Blackbird Web SDK',
    plugins: [
      segmentPlugin({
        writeKey: config.segment.WRITE_KEY,
      }),
    ],
  })

  export function track(message: string, payload: any) {
    analytics.track(message, payload)
  }
}
