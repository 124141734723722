import {
  PopupClosingTrigger,
  PopupHeader as PopupContainer,
  PopupHeaderImage,
  PopupHeaderInfo,
  PopupHeaderInner,
  PopupHeaderName,
} from './styled'
import * as React from 'react'

interface Props {
  popupTitle?: string
  popupImage?: string
  onCancel?: () => void
}

const PopupHeaderView = ({ popupTitle, popupImage, onCancel }: Props) => {
  return (
    <PopupContainer>
      <PopupHeaderInner>
        {popupImage && <PopupHeaderImage businessImageUrl={popupImage} />}
        <PopupHeaderInfo showingImage={!!popupImage}>
          {popupTitle && <PopupHeaderName>{popupTitle}</PopupHeaderName>}
        </PopupHeaderInfo>
      </PopupHeaderInner>
      {onCancel && <PopupClosingTrigger onClick={onCancel} />}
    </PopupContainer>
  )
}

export default PopupHeaderView
