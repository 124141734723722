import BlackbirdHandler from '../../common/lib/blackbirdHandler'
import ReactDOM from 'react-dom'
import React from 'react'

export default class ApplePay {
  #domElement

  constructor(publicKey, businessId) {
    this.publicKey = publicKey
    this.businessId = businessId
    this.blackbirdHandler = new BlackbirdHandler(this.publicKey)
  }

  setPaymentDetails = (paymentId, amount, label) => {
    this.paymentId = paymentId
    this.amount = amount
    this.label = label
  }

  setSuccessFunction = (handler) => {
    this.successFunction = handler
  }

  setFailureFunction = (handler) => {
    this.failureFunction = handler
  }

  mount = ({ container, buttonType, style }) => {
    if (typeof container === 'string' || container instanceof String) {
      this.#domElement = document.querySelector(container)
    } else {
      this.#domElement = container
    }

    this.loadApplePaySdk()
    ReactDOM.unmountComponentAtNode(this.#domElement)

    ReactDOM.render(
      <React.StrictMode>
        <apple-pay-button
          buttonstyle="black"
          type={buttonType}
          locale="en"
          onClick={() => this.#createApplePaySession()}
          style={style}
        ></apple-pay-button>
      </React.StrictMode>,
      this.#domElement
    )
  }

  #createApplePaySession = () => {
    const request = {
      countryCode: 'ZA',
      currencyCode: 'ZAR',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard', 'amex', 'electron', 'maestro'],
      total: {
        label: this.label,
        type: 'final',
        amount: this.amount,
      },
    }
    // eslint-disable-next-line no-undef
    const session = new ApplePaySession(3, request)
    this.#setOnValidateMerchantHandler(session)
    this.#setOnPaymentMethodSelectedHandler(session)
    this.#setOnPaymentAuthorizedHandler(session)

    session.begin()
  }

  #setOnPaymentAuthorizedHandler = (session) => {
    session.onpaymentauthorized = (event) => {
      const paymentDataString = JSON.stringify(event.payment.token.paymentData)
      const paymentDataBase64 = btoa(paymentDataString)

      const body = {
        answer: { type: 'apple_pay_token', applePayToken: paymentDataBase64 },
      }

      this.blackbirdHandler
        .completePayment(this.paymentId, body, this.businessId, null)
        .then((paymentResponse) => {
          if (paymentResponse.status === 'succeeded') {
            session.completePayment({
              // eslint-disable-next-line no-undef
              status: ApplePaySession.STATUS_SUCCESS,
            })
            this.successFunction()
          } else {
            session.completePayment({
              // eslint-disable-next-line no-undef
              status: ApplePaySession.STATUS_FAILURE,
            })
            this.failureFunction(paymentResponse.error)
          }
        })
    }
  }

  #setOnPaymentMethodSelectedHandler = (session) => {
    session.onpaymentmethodselected = () => {
      // Define ApplePayPaymentMethodUpdate based on the selected payment method.
      const update = {
        newTotal: {
          label: this.label, // Check what this can be
          type: 'final',
          amount: this.amount,
        },
      }

      session.completePaymentMethodSelection(update)
    }
  }

  #setOnValidateMerchantHandler = (session) => {
    session.onvalidatemerchant = async () => {
      const paymentID = this.paymentId
      const idempotencyKey = null

      const body = { paymentMethodDetails: { type: 'apple_pay' } }

      this.blackbirdHandler
        .completePayment(paymentID, body, this.businessId, idempotencyKey)
        .then((paymentSession) => {
          session.completeMerchantValidation(paymentSession.applePaySession)
        })
    }
  }


  loadApplePaySdk = () => {
    const script = document.createElement('script')
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
    script.onload = function () {}
    script.onerror = function () {
      // There was an error loading the SDK
      console.error('Failed to download Apple Pay sdk')
    }
    document.head.appendChild(script)
  }
}
