/*eslint no-undef: "error"*/
import { makeId } from '../../../../common/utils/utils'
import { insertIframe } from './deviceFingerprintView'
import { Segment } from '../../../../common/lib/segment/Segment'

/** This class is responsible for handling the flow of doing capturing a device fingerprint */
class DeviceFingerprintHandler {
  captureDeviceFingerprint = ({ accessToken, bin, cardData, paymentId }) => {
    if (accessToken && bin) {
      return this.#attachIframe(accessToken, bin, cardData, paymentId).catch(
        (result) => {
          throw {
            message: result.failureMessage,
          }
        }
      )
    }
  }

  #attachIframe = (accessToken, bin, cardData, paymentId) => {
    // If a popup is already running, we should kill it before we start a new one
    // Create an identifier that will be used to reference this auth
    this.currentIdentifier = `capture-device-fingerprint-${makeId()}`
    const form = insertIframe(this.currentIdentifier, accessToken, bin)
    const eventHandler = this.addCompleteEventHandler(
      accessToken,
      cardData,
      bin,
      paymentId
    )
    form.submit()
    return eventHandler
  }

  /**
   * Handle the events for auth in an iframe, and return a promise that will contain a result once processing is complete
   *
   * Auth takes place in an iFrame and that iFrame is able to post events up to this parent iFrame.  We listen for
   * those events here so that we can correctly process them and take actions based on those events.
   *
   * We also do a check to make sure that the events we are receiving are the correct events and ignore events that
   * are not connected with the currentIdentifier at the time that we started listening.
   */

  addEventListenerHelper = (callback, identifier) => {
    // Create IE + others compatible event handler
    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent'
    const eventListener = window[eventMethod]
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

    // Listen to message from child window
    eventListener(
      messageEvent,
      function (e) {
        callback(e)
      },
      false
    )
  }

  addCompleteEventHandler = (accessToken, cardData, bin, paymentId) =>
    new Promise((resolve) => {
      this.addEventListenerHelper((e) => {
        let data = {}
        if (typeof e.data === 'string') {
          data = JSON.parse(e.data)
        }

        if (data.MessageType === 'profile.completed') {
          Segment.track('DEVICE_FINGERPRINT_CAPTURED', {
            paymentId: paymentId,
          })
          resolve({
            accessToken,
            bin,
            cardData,
          })
        }
      }, accessToken)
    })
}

/** we want to actually use a singleton version of this class */
const instance = new DeviceFingerprintHandler()

/** Only want to expose a single method */
export default {
  captureDeviceFingerprint: instance.captureDeviceFingerprint,
}
