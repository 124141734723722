import * as React from 'react'
import { useEffect, useState } from 'react'
import './Alert.scss'
// @ts-ignore
import config from '../../../../config'

const warning = require('./images/warning.png')

interface Alert {
  alertType: string
  enabled: boolean
  message: string
}

type AlertProps = {
  dropIn?: boolean
}

const AlertBar = ({ dropIn }: AlertProps) => {
  const [alert, setAlert] = useState<Alert>({
    alertType: '',
    enabled: false,
    message: '',
  })

  const alertStyle = dropIn ? 'yc-alert-box-dropin' : 'yc-alert-box'

  useEffect(() => {
    fetch(`${config.firebase.ENDPOINT}`)
      .then((response) => response.json())
      .then((response) => setAlert(response))
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return alert.enabled ? (
    <div className={alertStyle}>
      <img className="yc-warning" src={warning} /> {alert.message}
    </div>
  ) : null
}

export default AlertBar
