import * as React from 'react'
import * as ReactDOM from 'react-dom'
import CheckoutConfiguration, {
  DisplayMethod,
} from './models/CheckoutConfiguration'
// @ts-ignore
import $ from 'domtastic'
// @ts-ignore
import { makeId } from '../../common/utils/utils'

import Checkout from './Checkout'
import Popup from './Popup'

export default class CheckoutHandler {
  private checkoutId: bigint = makeId()
  private checkoutContainerId = `yc-checkout-container[${this.checkoutId}]`
  private checkoutContainer = $(`<div id="${this.checkoutContainerId}"/>`)

  constructor(private configuration: CheckoutConfiguration) {
    if (configuration.onClose && !configuration.onCancel) {
      configuration.onCancel = configuration.onClose
    }
    // Patch up our configuration with sensible defaults
    configuration.displayMethod =
      configuration.displayMethod || DisplayMethod.Auto
    this.initialize()
  }

  /** Mount the container that will be responsible for rendering our checkout related methods and buttons*/
  private initialize = () => {
    const displayMethod = this.configuration.displayMethod

    // Stick our checkout container onto the page, pick a location based on if we have a mount etc
    if (displayMethod === DisplayMethod.Auto && this.configuration.mountElem) {
      $(this.configuration.mountElem).append(this.checkoutContainer)
    } else if (displayMethod === DisplayMethod.Auto && document.currentScript) {
      $(document.currentScript).after(this.checkoutContainer)
    } else {
      // This is the fallback case the only occurs if you have not specified a location
      if (this.configuration.displayMethod != DisplayMethod.Manual)
        console.warn(
          'No mount location was specified and one could not be calculated so we have fallen back to manual `DisplayMethod`.'
        )
      $('body').append(this.checkoutContainer)
      this.configuration.displayMethod = DisplayMethod.Manual
    }

    if (this.configuration.displayMethod === DisplayMethod.Auto) {
      this.mountCheckoutContainer()
    }
  }

  private mountCheckoutContainer = (startShown = false) => {
    ReactDOM.render(
      React.createElement(
        Checkout,
        { configuration: this.configuration, startShown },
        null
      ),
      this.checkoutContainer[0]
    )
  }

  showPopup = () => {
    if (this.configuration.displayMethod === DisplayMethod.Auto) {
      console.warn(
        'Can not manually invoke the showPopup method when checkout is set to automatically show itself.'
      )
      return
    }
    $('.yc-auto-shown-popup').remove()
    this.checkoutContainer.addClass('yc-auto-shown-popup')

    // Actually show our popup on the page
    this.mountCheckoutContainer(true)
  }
}

/** Inject our popup into an html page at the location defined by `element`*/
const injectPopupApp = (
  configuration: CheckoutConfiguration,
  element: HTMLElement | string
) => {
  ReactDOM.render(
    React.createElement(Popup, { configuration }, null),
    $(element)[0]
  )
}

// @ts-ignore
window.BlackbirdSDK__injectPopupApp = injectPopupApp
