import NextActionHandler from '../../v1/flex/auth/NextActionHandler'
import { convertApiError } from '../utils/utils'
import BlackbirdApi from './blackbirdApi'
import SentryLib from '../lib/Sentry'
import config from '../../../config'

export default class BlackbirdHandler {
  constructor(publicKey) {
    this.publicKey = publicKey
    this.blackbirdApi = new BlackbirdApi(this.publicKey)
  }

  getAccessTokenForPayment = (paymentId, merchantId, idempotentKey) => {
    this.paymentId = paymentId
    return this.blackbirdApi
      .getAccessToken(paymentId, merchantId, idempotentKey)
      .then((response) => {
        return { accessToken: response.accessToken, paymentId: paymentId }
      })
  }

  completePaymentAndProcessNextAction = (
    paymentId,
    body,
    merchantId,
    idempotencyKey,
    embeddedContainer
  ) => {
    const { mode } = body
    const stringifiedBody = JSON.stringify(body)

    return this.blackbirdApi
      .completePaymentRequest(
        paymentId,
        stringifiedBody,
        merchantId,
        idempotencyKey
      )
      .then((completePaymentResult) =>
        NextActionHandler.maybeRedirect(
          { ...completePaymentResult, mode },
          embeddedContainer
        )
      )
      .then((completePaymentResult) => ({
        mode,
        id: completePaymentResult.id,
        status: completePaymentResult.status,
        source: completePaymentResult.paymentMethodDetails,
        paymentMethod: completePaymentResult.paymentMethod,
        ...convertApiError(completePaymentResult.error),
      }))
  }

  completePayment = (paymentId, body, merchantId, idempotencyKey) => {
    const { mode } = body
    const stringifiedBody = JSON.stringify(body)

    return this.blackbirdApi
      .completePaymentRequest(
        paymentId,
        stringifiedBody,
        merchantId,
        idempotencyKey
      )
      .then((completePaymentResult) => ({
        mode,
        id: completePaymentResult.id,
        status: completePaymentResult.status,
        bin: completePaymentResult?.nextAction?.deviceFingerprint?.bin,
        paymentMethod: completePaymentResult.paymentMethod,
        ...convertApiError(completePaymentResult.error),
        applePaySession: completePaymentResult?.nextAction?.applePaySession,
      }))
  }
}
