/** The complete token flow handles the process of completing a payment based on card information collected from
 * flex form and the payment Id
 * */
import { Deferred } from '../../../../common/utils/Deferred'
import BlackbirdHandler from '../../../../common/lib/blackbirdHandler'
import { Mode } from '../../../checkout/models/CheckoutConfiguration'
import DeviceFingerprintHandler from '../../security/deviceFingerPrint/deviceFingerprintHandler'
import { Segment } from '../../../../common/lib/segment/Segment'

// @ts-ignore
export default class SavedCardCompletePaymentFlow {
  constructor(configuration, parameters) {
    this.configuration = configuration
    this.parameters = parameters
    this.deferred = new Deferred()
    this.#runFlowWithSavedCardDetails()
  }

  /** run the flow and charge a saved card*/
  #runFlowWithSavedCardDetails = () => {
    return this.#completePaymentWithPaymentMethodWithSavedCard(
      this.parameters.id,
      this.parameters,
      this.parameters.businessId,
      this.configuration.publicKey
    )
      .then((completePaymentResult) => {
        this.completePaymentResult = completePaymentResult
        return this.#captureDeviceFingerprint()
      })
      .then((result) => {
        return this.#maybePerformRedirectURlNextAction(result)
      })
      .then(this.deferred.resolve)
      .catch(this.deferred.reject)
  }

  #completePaymentWithPaymentMethodWithSavedCard = (
    paymentId,
    completePaymentRequestBody,
    businessId,
    publicKey
  ) => {
    const blackbirdHandler = new BlackbirdHandler(publicKey)
    return blackbirdHandler.completePayment(
      paymentId,
      completePaymentRequestBody,
      businessId,
      this.parameters.idempotencyKey
    )
  }

  #captureDeviceFingerprint = () => {
    Segment.track('DEVICE_FINGERPRINT_CAPTURING_STARTED', {
      paymentId: this.configuration.id,
    })
    if (this.completePaymentResult.status === 'pending') {
      const blackbirdHandler = new BlackbirdHandler(
        this.configuration.publicKey
      )
      return blackbirdHandler
        .getAccessTokenForPayment(
          this.parameters.id,
          this.parameters.businessId
        )
        .then(({ accessToken, paymentId }) => {
          return DeviceFingerprintHandler.captureDeviceFingerprint({
            accessToken: accessToken,
            bin: this.completePaymentResult.bin,
            paymentId: paymentId,
          })
        })
        .then(({ accessToken, bin }) => {
          return { accessToken, bin }
        })
    } else {
      return this.completePaymentResult
    }
  }

  #maybePerformRedirectURlNextAction = (deviceFingerprintResult) => {
    if (this.completePaymentResult.status === 'pending') {
      return this.#completePaymentWithNextActionAnswer({
        answerResult: deviceFingerprintResult.accessToken,
        answerType: 'device_fingerprint',
      })
    } else {
      return deviceFingerprintResult
    }
  }

  #completePaymentWithNextActionAnswer = ({ answerResult, answerType }) => {
    const {
      businessId,
      metadata,
      mode = Mode.payment,
      nextActionContainer,
    } = this.parameters
    const { id: paymentId, customer, idempotencyKey } = this.parameters

    //Todo build payload dynamically based off answer type
    const completePaymentRequestBody = {
      ...(customer ? { customer } : {}),
      mode,
      answer: {
        type: answerType,
        deviceFingerprint: answerResult,
      },
      metadata,
    }

    const blackbirdHandler = new BlackbirdHandler(this.configuration.publicKey)
    return blackbirdHandler.completePaymentAndProcessNextAction(
      paymentId,
      completePaymentRequestBody,
      businessId,
      idempotencyKey,
      nextActionContainer
    )
  }

  promise = () => {
    return this.deferred.promise
  }
}
