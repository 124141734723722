import BlackbirdHandler from '../../common/lib/blackbirdHandler'

export default class EFT {
  constructor(publicKey, embeddedContainer, businessId) {
    this.publicKey = publicKey
    this.eftContainer = embeddedContainer
    this.businessId = businessId
  }

  submit = (extraData = {}) => {
    const paymentID = extraData.id
    const idempotencyKey = extraData.idempotencyKey

    const body = { paymentMethodDetails: { type: 'instant_eft' } }

    const blackbirdHandler = new BlackbirdHandler(this.publicKey)
    return blackbirdHandler.completePaymentAndProcessNextAction(
      paymentID,
      body,
      this.businessId,
      idempotencyKey,
      this.eftContainer
    )
  }
}
