export default class SubmitParameters {
  constructor(
    public id?: string,
    public paymentMethod?: string,
    public customer?: string,
    public businessId?: string,
    public callback?: (token: any) => void,
    public paymentType?: PaymentType,
    public metadata?: Map<string, any>
  ) {}
}

export enum PaymentType {
  Card = 'CARD',
  EFT = 'EFT',
}
