import { VGS_FIELD_DETAILS } from './vgs'
import { makeId } from '../../common/utils/utils'
import { Deferred } from '../../common/utils/Deferred'
import equal from 'fast-deep-equal'
import messages from './vgs/ValidationMessages'
import Events from '../Events'

/**
 * Use FlexField instances to collect sensitive information in your payment forms.
 * To create a FlexField instance, use `flex.field`.
 *
 * Please note: Because this interface will be accessible to the user we have chosen to make use
 * of the new JS standard for private methods and fields so that we limit how much of our interface is actually
 * exposed to the user.
 */
export default class FlexField {
  #baseStyle
  #invalidStyle
  #currentStyle

  #fieldType
  #fieldDetails
  #fieldName
  #fieldIdentifier
  #addCardBrands
  #onFieldMount
  #domElement
  #options
  #vgsSecureForm
  #vgsCollectField = new Deferred()

  /** Who is the field that should be focused next once this field is ready*/
  #nextSibling

  /** current state of the vgs field*/
  #currentState

  #hasLoggedValidationSuccess = false
  #hasLoggedVGSReady = false

  /** A deferred used to track if the underlying vgs field is ready*/
  #vgsCollectFieldReadyDeferred = new Deferred()
  #vgsCollectFieldReady = false

  /** Has this field ever gained focus, useful for validation*/
  #hasGainedFocus = false
  /** Has this field ever lost focus, useful for validation*/
  #hasLostFocus = false

  /** If the fields current state from a flex perspective*/
  state = Object.freeze({
    focus: false,
    complete: false,
    ready: false,
    editing: false,
    error: undefined,
    empty: true,
  })

  errorMessage = () => this.state?.error?.message

  /** All of our event handlers for the events that we allow people to listen on*/
  #onEventHandlers = {}

  constructor(fieldType, vgsSecureForm, options, onFieldMount) {
    this.#fieldType = fieldType
    this.#currentStyle = Object.freeze(options?.style || {})
    this.#baseStyle = options?.style?.base || {}
    this.#invalidStyle = options?.style?.invalid || {}
    this.#fieldDetails = VGS_FIELD_DETAILS[fieldType]
    this.#fieldName = this.#fieldDetails.name
    this.#fieldIdentifier = `${this.#fieldDetails.name}-${makeId()}`
    this.#onFieldMount = onFieldMount
    this.#options = options
    this.#vgsSecureForm = vgsSecureForm
    this.#options.autoComplete =
      this.#options.autoComplete || this.#fieldDetails.defaultAutoComplete
    this.#addCardBrands = options.schemaOverrides
  }

  /** this is the information for the vgs show card icon */
  #cardIconProps = () =>
    this.#options?.showCardIcon === false
      ? false
      : this.#options?.showCardIcon || this.#fieldDetails.showCardIcon

  /**
   * The `FlexField.mount` method attached your FlexField to the DOM.  This method accepts
   * either CSS selector (e.g. `#card-number-field`) or a DOM element.
   */
  mount(domElement, options) {
    // Store our domElement
    if (typeof domElement === 'string' || domElement instanceof String) {
      this.#domElement = document.querySelector(domElement)
    } else {
      this.#domElement = domElement
    }

    // Notify our form that this field has been mounted
    this.#onFieldMount(this.#fieldName, [this, this.#updateFieldState])

    this.#vgsCollectFieldReadyDeferred.promise.then(() => {
      if (!this.#hasLoggedVGSReady) {
        this.#hasLoggedVGSReady = true
      }
    })

    this.#vgsSecureForm.then((vgsSecureForm) => {
      // Create our vgsCollect field
      options = {
        css: this.#baseStyle,
        ...(options || this.#options),
        type: this.#fieldDetails.vgsFieldType,
        validations: this.#fieldDetails.validations,
        name: this.#fieldName,
        // here we want to use the value the user passed in, but if they passed nothing in we want to fall back to the default, unless they passed in false
        showCardIcon: this.#cardIconProps(),
        addCardBrands: this.#addCardBrands,
      }

      const vgsCollectField = vgsSecureForm.field(domElement, options)
      this.#vgsCollectField.resolve(vgsCollectField)

      // This just makes sure if we are using a pre-filled value that it gets recognized and validated
      setTimeout(() => options.value && vgsCollectField.focus(), 750)

      this.#domElement.classList.add(FLEX_FIELD_CLASS)
      this.#domElement.classList.add(`${FLEX_FIELD_CLASS}--${this.#fieldType}`)
    })
  }

  /** Set a value for this field so that we can basically prefill it when we want to*/
  updateValue(value) {
    const onComplete = new Deferred()

    value = value || ''
    const doUpdateValue = (value) => {
      const properties = {
        css: [{ ...this.#baseStyle }],
        showCardIcon: [this.#cardIconProps()],
        value,
      }

      this.#setAdditionalProperties(properties)
    }

    let position = 0
    const intervalId = setInterval(() => {
      if (position > value.length) {
        clearInterval(intervalId)
        this.focus()
        onComplete.resolve()
      } else {
        doUpdateValue(value.substring(0, position))
      }
      position += 1
    }, 40)
    return onComplete.promise
  }

  /** Clears the value(s) of the FlexField.*/
  clear = () => this.updateValue()

  setNextSibling(elem) {
    this.#nextSibling = elem
  }

  focus() {
    this.#vgsCollectField.promise.then((vgsCollectField) =>
      vgsCollectField.focus()
    )
  }

  focusNext = () => {
    this.#nextSibling?.focus()
  }

  currentStyle = () => this.#currentStyle

  updateStyle = (style) => {
    this.#baseStyle = style?.base || {}
    this.#invalidStyle = style?.invalid || {}
    this.#stateDrivenStyling()
  }

  /**
   * Update the style of the input field by giving it a new css.  This method will tell VGS to override all the css it has,
   * but is a useful way of allowing us to add validation state specific css etc.
   */
  #updateFieldStyling = (css = {}) => {
    const properties = {
      css: [{ ...this.#baseStyle, ...css }],
      showCardIcon: [this.#cardIconProps()],
    }

    this.#setAdditionalProperties(properties)
  }

  #setAdditionalProperties = (properties) => {
    // post a message with the properties we are wanting to update.
    this.#vgsCollectField.promise.then((vgsField) => {
      vgsField?._postMessage({
        messageName: 'setProperties',
        additionalProperties: properties,
      })
    })
  }

  #currentSuggestedStyling = () => {
    if (!this.state.empty && this.state.error && !this.state.editing) {
      return this.#invalidStyle
    } else {
      return this.#baseStyle
    }
  }

  /** Change the field styling based on the field state, using the styles user passed inf or each of the states*/
  #stateDrivenStyling = () => {
    if (!this.state.empty && this.state.error && !this.state.editing) {
      this.#updateFieldStyling(this.#invalidStyle)
    } else {
      this.#updateFieldStyling()
    }
  }

  /** whenever the state changes this will be called and passed in a state*/
  #updateFieldState = (state, wasSubmitted = false) => {
    const currentState = this.state

    this.#currentState = state
    this.#vgsCollectFieldReadyDeferred.resolve(true)

    if (state.isFocused) {
      this.#hasGainedFocus = true
    } else if (this.#hasGainedFocus) {
      this.#hasLostFocus = true
    }

    let updatedFlexState = {}

    const errorMessage =
      state.errorMessages[state.errorMessages?.length - 1 || 0]
    // Some things we only want to do once a field has been either submitted or has lost focus before, and is being edited
    if (
      errorMessage &&
      !state.isValid &&
      ((this.#hasLostFocus && state.isDirty) || wasSubmitted)
    ) {
      // Add the correct error message into our FlexState object
      updatedFlexState.error = {
        message: messages.message(
          this.#fieldDetails.vgsFieldType,
          errorMessage
        ),
        type: 'validation_error',
      }
    }

    //TODO: Add logging for when the fields pass validation (which will give us an idea whether vgs loads or not without being spammy)
    updatedFlexState.complete = state.isValid && !state.isEmpty
    updatedFlexState.focus = state.isFocused || false
    updatedFlexState.editing = (state.isDirty && state.isFocused) || false
    updatedFlexState.empty = state.isEmpty || false

    // once we have been able to get this event it means we are actually ready field wise
    updatedFlexState.ready = true

    if (!this.#hasLoggedValidationSuccess && updatedFlexState.complete) {
      this.#hasLoggedValidationSuccess = true
    }
    if (!equal(updatedFlexState, currentState)) {
      const validationChanged =
        updatedFlexState.complete !== currentState.complete ||
        updatedFlexState.editing !== currentState.editing

      this.state = Object.freeze(updatedFlexState)
      this.#onEventHandlers[Events.CHANGE]?.forEach((handler) => handler(this))

      if (validationChanged) {
        this.#onEventHandlers[Events.VALIDITY_CHANGE]?.forEach((handler) =>
          handler(this)
        )
      }

      // At this point we know that some of the field state data changed so good to try update styling
      this.#stateDrivenStyling()
    }

    if (updatedFlexState.focus !== (currentState?.focus === true)) {
      if (updatedFlexState.focus) {
        this.#onEventHandlers[Events.FOCUS]?.forEach((handler) => handler(this))
      } else {
        this.#onEventHandlers[Events.BLUR]?.forEach((handler) => handler(this))
      }
    }
  }

  /**
   * To receive communication and updates from your FlexField you need to be listening for events.
   *
   * @param event The name of the event. , which must be one of [change, ready, focus, blur]
   * @param handler handler(event) => void is a callback function that you provide that will be
   * called when the event is fired.
   */
  on = (event, handler) => {
    switch (event) {
      // This is how we can listen for the field being ready.  This is based on the underlying vgs field having state.
      case 'ready':
        this.#vgsCollectFieldReadyDeferred.promise.then(() =>
          setTimeout(() => handler(this), 1)
        )
        break
      // We handle the other event listeners by just storing them in our listeners object to be invoked as needed
      default:
        if (!this.#onEventHandlers[event]) {
          this.#onEventHandlers[event] = []
        }

        this.#onEventHandlers[event].push(handler)
        break
    }
  }
}

/** All our flex fields will have this class added to them once they have been mounted*/
export const FLEX_FIELD_CLASS = 'yc-flex-field'
