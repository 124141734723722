/*eslint no-undef: "error"*/
/**
 * Remote authentication takes place when we are authenticating a charge intent with a 3rd party service like
 * 3D secure.  The service is opened in an iFrame and we then show the user whatever is inside the iFrame so
 * that they can do authentication.  Once authentication is complete we will then return an updated charge intent
 * that will allow the user to proceed.  That charge intent could actually contain another authentication url, and
 * we would then show that.
 */
import $ from 'domtastic'
import { addEventListener, makeId } from '../../../common/utils/utils'
import {
  closePopup,
  injectAuthIframeStyles,
  injectEmbeddedAuthIframeStyles,
  insertIframe,
  populateEmbeddedContainer,
} from './NextActionView'
import FlexForm from '../FlexForm'

/** This class is responsible for handling the flow of doing authentication */
class NextActionHandler {
  constructor() {
    this.cardNextActionReasons = ['3ds_challenge', 'device_fingerprint']
    this.eftNextActionReasons = ['visit_provider']
  }

  /** Show the authentication iFrame to the user and actually start processing the authentication */
  maybeRedirect = (completePaymentResult, embeddedContainer) => {
    if (
      completePaymentResult.status === 'pending' &&
      completePaymentResult.nextAction.redirectToUrl.redirectUrl
    ) {
      // TODO: PS: 09/06/2022: We should add the paymentMethod to the completePaymentResult rather than trying to figure it out.
      const iframePaymentType = this.decideIframePaymentType(
        completePaymentResult
      )
      return this.#attachIframe(
        completePaymentResult.id,
        completePaymentResult.nextAction.redirectToUrl.redirectUrl,
        embeddedContainer,
        iframePaymentType
      )
        .catch((result) => {
          throw {
            message: result.failureMessage,
          }
        })
        .then(this.maybeRedirect)
    }
    return completePaymentResult
  }

  #attachIframe = (
    paymentId,
    authenticationURL,
    embeddedContainer,
    iframePaymentType
  ) => {
    // If a popup is already running, we should kill it before we start a new one
    this.closePopup()
    // Create an identifier that will be used to reference this auth
    this.currentIdentifier = `auth-content-${makeId()}`

    this.embedOrInsertIframe(
      authenticationURL,
      embeddedContainer,
      iframePaymentType
    )

    return this.addCompleteEventHandler(paymentId)
  }

  embedOrInsertIframe(authenticationURL, embeddedContainer, iframePaymentType) {
    if (embeddedContainer != null) {
      injectEmbeddedAuthIframeStyles()
      populateEmbeddedContainer(
        this.currentIdentifier,
        authenticationURL,
        embeddedContainer
      )
    } else {
      injectAuthIframeStyles()
      insertIframe(this.currentIdentifier, authenticationURL)
    }

    FlexForm.handleNextAction(iframePaymentType)
  }

  decideIframePaymentType = (iframePaymentType) => {
    if (
      iframePaymentType.nextAction.reason != null &&
      this.eftNextActionReasons.includes(iframePaymentType.nextAction.reason)
    )
      return 'instant_eft'
    else if (
      iframePaymentType.nextAction.reason != null &&
      this.cardNextActionReasons.includes(iframePaymentType.nextAction.reason)
    )
      return 'card'
    else return 'unknown'
  }

  /** close this popup and clear the page of anything we added specifically for the popup */
  closePopup = () => {
    closePopup(this.$identifier()?.[0])
    this.currentIdentifier = undefined
  }

  /**
   * Handle the events for auth in an iframe, and return a promise that will contain a result once processing is complete
   *
   * Auth takes place in an iFrame and that iFrame is able to post events up to this parent iFrame.  We listen for
   * those events here so that we can correctly process them and take actions based on those events.
   *
   * We also do a check to make sure that the events we are receiving are the correct events and ignore events that
   * are not connected with the currentIdentifier at the time that we started listening.
   */
  addCompleteEventHandler = (paymentId) =>
    new Promise((resolve) => {
      addEventListener((e) => {
        let completePaymentAuthResponse = e.data
        let error = completePaymentAuthResponse.error
        this.closePopup()
        if (error) {
          resolve({
            id: completePaymentAuthResponse.paymentId,
            status: completePaymentAuthResponse.paymentState,
            error: error,
          })
        } else {
          resolve({
            id: completePaymentAuthResponse.paymentId,
            status: completePaymentAuthResponse.paymentState,
            paymentMethodDetails:
              completePaymentAuthResponse.paymentMethodDetails,
            paymentMethod: completePaymentAuthResponse.paymentMethod,
          })
        }
      }, paymentId)
    })

  /** convenience function to get the current identifier */
  $identifier = () => $(`#${this.currentIdentifier}`)
}

/** we want to actually use a singleton version of this class */
const instance = new NextActionHandler()

/** Only want to expose a single method */
export default {
  maybeRedirect: instance.maybeRedirect,
}
