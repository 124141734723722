import * as React from 'react'
import { SaveCardForFutureUseInput, SaveForFutureUseLabel } from './styled'
import { CallbackType } from '../../index'
import { ChangeEvent } from 'react'

interface Props {
  setSaveCard: CallbackType
  saveCard: boolean
}

const SaveCardForFutureUseCheckbox = ({ setSaveCard, saveCard }: Props) => {
  return (
    <SaveForFutureUseLabel>
      <SaveCardForFutureUseInput
        id="saveCardCheckBox"
        name={'Save Card For Future Use'}
        type={'checkbox'}
        checked={saveCard}
        onChange={(onChangeEvent: ChangeEvent<HTMLInputElement>) => {
          setSaveCard(onChangeEvent.target.checked)
        }}
      />
      Save card for next time
    </SaveForFutureUseLabel>
  )
}

export default SaveCardForFutureUseCheckbox
