import styled from 'styled-components'

export const SaveCardForFutureUseInput = styled.input`
  font-size: 14 px;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: centre;
  transform: scale(1.4);
`
export const SaveForFutureUseLabel = styled.label`
  display: flex;
  align-items: flex-end;
  border-block-end: 100px;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 10px;
  column-gap: 10px;
`
