import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import CheckoutConfiguration, { Mode } from '../models/CheckoutConfiguration'
import PopupCardView from './components/PopupCardView'
// @ts-ignore
import BlackbirdSDK from '../../BlackbirdSDK'
import Money from '../../../common/utils/Money'

interface Props {
  /** The configuration for this popup component*/
  configuration: CheckoutConfiguration
  /**
   * We allow an error message to be passed into the component if one needs to be enforced.
   * This is especially useful for visually testing the component in storybook.
   */
  errorMessage?: string
}

export type CallbackType = (arg: boolean) => void

const Popup = ({ configuration }: Props) => {
  const onCancel = () => {
    //@ts-ignore
    window.onCancel()
  }

  let saveCardDefault = false

  if (configuration.paymentMethodDetails?.save != null) {
    saveCardDefault = configuration.paymentMethodDetails?.save
  }

  // @ts-ignore
  const onSuccess = (token) => {
    // @ts-ignore
    window.onSuccess(token)
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dropin, setDropin] = useState(undefined)
  const [dropinReady, setDropinReady] = useState(false)
  const [shake, setShake] = useState(false)
  const [save, setSave] = useState(saveCardDefault)

  const amount = new Money(
    configuration.amount || configuration.amountInCents,
    configuration.currency
  )

  const setSaveCard = useCallback<CallbackType>(
    (saveCard) => {
      setSave(saveCard)
    },
    [setSave]
  )

  useEffect(() => {
    setSave(saveCardDefault)
    const dropin = new BlackbirdSDK(configuration).dropin({
      ...configuration,
      layout: 'basic',
      metadata: configuration.metadata,
    })

    dropin.on(BlackbirdSDK.Events.VALIDITY_CHANGE, () => {
      // We want to set our error message to a validation error message
      const errorMessage = dropin.validationErrorMessage()
      const showErrorMessage = errorMessage?.trim() && true
      setShowErrorMessage(showErrorMessage)
      showErrorMessage && setErrorMessage(errorMessage)
      setIsValid(dropin.isValid())
    })

    setTimeout(() => dropin.mount('#drop-in'), 1)
    setDropin(dropin)

    dropin.on(BlackbirdSDK.Events.READY, () => {
      // When the dropin is ready we maybe want to drive the showing and hiding based on this above everything else we doing
      setDropinReady(true)
    })

    // We also want to make sure there is a max time before the popup shows so that the user can
    // interact with things like the close button if they need to
    setTimeout(() => {
      setDropinReady(true)
    }, 8000)
  }, [])

  /** Handle the user pressing the submit payment button*/
  const onSubmit = () => {
    setShowErrorMessage(false)
    setIsSubmitting(true)
    // @ts-ignore
    dropin
      .submit({
        id: configuration.id,
        customer: configuration.customer,
        paymentMethodDetails: {
          usable: configuration.paymentMethodDetails?.usable || 'online',
          save,
        },
      })
      .then((result: any) => {
        setIsSubmitting(false)
        if (result.error) {
          setErrorMessage(result.error.message)
          setShowErrorMessage(true)
          setShake(true)
          setTimeout(() => setShake(false), 200)
        } else {
          // otherwise we have succeeded, so lets pass the token back
          onSuccess(result)
        }
      })
      .catch((result: any) => {
        setIsSubmitting(false)
      })
  }

  return (
    <PopupCardView
      amount={amount.prettyPrint()}
      buttonText={configuration.buttonText}
      dropInId="drop-in"
      popupTitle={configuration.name}
      showAmount={configuration.showAmount}
      popupImage={configuration.image}
      popupDescription={configuration.description}
      onCancel={onCancel}
      errorMessage={errorMessage}
      showErrorMessage={showErrorMessage}
      isValid={isValid}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      dropinReady={dropinReady}
      shake={shake}
      setSaveCard={setSaveCard}
      saveCard={save}
      showSaveCardCheckbox={
        configuration.mode !== Mode.subscription &&
        configuration.showSaveCardCheckbox
      }
    />
  )
}

export default Popup
