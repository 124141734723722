import React, { useState } from 'react'
import './BaseCardView.scss'
import CvvTooltip from './CvvTooltip'
import { CSSTransition } from 'react-transition-group'
import Loader from '../../../../common/components/Loader'
import AlertBar from '../../../../common/components/Alert/Alert'

/**
 * The plain card view is laid out but has no formatting, we can add formatting to it later using the provided classes.
 * This is our most basic form structure and the others leverage this for their layout.
 */
const BaseCardView = ({ formInfo, showCvvTooltip = true }) => {
  const {
    idPrefix,
    flexReady,
    flexFields,
    showSubmitButton,

    errorMessage,
    showErrorMessage,
    submitButtonText,
    isSubmitting,
    onSubmit,
  } = formInfo

  const loadingInputClass = flexReady ? '' : 'yc-card-input-wrapper-loading'

  const [cardNumberState, setCardNumberState] = useState({})
  const [cardExpiryState, setCardExpiryState] = useState({})
  const [cardCvvState, setCardCvvState] = useState({})

  flexFields?.cardNumberField?.on('change', (field) => {
    setCardNumberState(field?.state)
  })

  flexFields?.cardExpiryField?.on('change', (field) => {
    setCardExpiryState(field?.state)
  })

  flexFields?.cardCvvField?.on('change', (field) => {
    setCardCvvState(field?.state)
  })

  // State classes are attached to our fields as we update their state [focused, invalid, placeholder]
  const stateClasses = (state) => {
    const focusClass = state?.focus ? 'yc-field-group--focused' : ''
    const errorClass =
      state?.error && !state?.editing ? 'yc-field-group--invalid' : ''
    const placeholderClass = state?.empty ? 'yc-field-group--placeholder' : ''

    return `${placeholderClass} ${focusClass} ${errorClass}`.trim()
  }

  return (
    <>
      <div className="yc-card-form-container">
        <AlertBar dropIn />
        <div
          className={`yc-field-group--card-number yc-field-group ${stateClasses(
            cardNumberState
          )}`}
        >
          <label>Card number</label>
          <div
            className={`yc-card-input-wrapper ${loadingInputClass}`}
            id={`yc-card-number-${idPrefix}`}
          />
        </div>
        <div className="yc-expiry-cvv-holder">
          <div
            className={`yc-field-group--card-expiry yc-field-group ${stateClasses(
              cardExpiryState
            )}`}
          >
            <label>Expiry date</label>
            <div
              className={`yc-card-input-wrapper ${loadingInputClass}`}
              id={`yc-card-expiry-${idPrefix}`}
            />
          </div>
          <div
            className={`yc-field-group--card-cvv yc-field-group ${stateClasses(
              cardCvvState
            )}`}
          >
            <label>CVC</label>
            {showCvvTooltip && <CvvTooltip />}
            <div
              className={`yc-card-input-wrapper ${loadingInputClass}`}
              id={`yc-card-cvv-${idPrefix}`}
            />
          </div>
        </div>
      </div>
      <CSSTransition
        in={showErrorMessage}
        timeout={10}
        classNames="yc-error-message"
      >
        <div className="yc-error-message">{errorMessage}</div>
      </CSSTransition>
      {showSubmitButton && (
        <button className="yc-submit-button" onClick={onSubmit}>
          {isSubmitting ? <Loader /> : submitButtonText || 'Pay'}
        </button>
      )}
    </>
  )
}

export default BaseCardView
