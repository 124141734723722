import $ from 'domtastic'
import config from '../../../config'

export const makeId = (length = 18) => {
  let text = ''
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export const isoTimestamp = () => {
  let currentdate = new Date()
  return currentdate.toISOString()
}

export const injectStyles = (rule) => {
  return $('body').append(`
      <div>
        <style>
            ${rule}
        </style>
      </div>
    `)
}

export const sdkJsUrl = () =>
  $('script').filter((script) => {
    return script.src?.endsWith('blackbird-web-sdk.js')
  })[0]

export const convertApiError = (error) => {
  if (typeof error !== 'undefined') {
    return {
      error: {
        errorType: error.code,
        message: error.description,
      },
    }
  } else return {}
}

/** unified way to add an event listener across different browsers*/
export const addEventListener = (callback, identifier) => {
  // Create IE + others compatible event handler
  const eventMethod = window.addEventListener
    ? 'addEventListener'
    : 'attachEvent'
  const eventListener = window[eventMethod]
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

  // Listen to message from child window
  eventListener(
    messageEvent,
    function (e) {
      if (!(e.origin === config.blackbird_web.url)) {
        return
      }
      if (identifier && e.data && e.data.identifier !== identifier) {
        return
      }
      callback(e)
    },
    false
  )
}

export const isSSR = typeof window === 'undefined' || !window.document

export const injectWebpackStyles = () => {
  if ($('#yc-injected-styles').length === 0 && window?.__yc__style_injector) {
    window?.__yc__style_injector?.((element) => {
      $('head').append(element)
      console.debug('YC> Styles injected.', element)
    })
  }
}

/** callback for when the escape key is pressed*/
export const onEscapePressed = (callback) => {
  // Attach a universal escape handler in this window so that the user can close with a single keypress
  document.addEventListener('keydown', function (event) {
    const key = event.key // Or const {key} = event; in ES6+
    if (key === 'Escape') {
      callback()
    }
  })
}

/** return a value if it is not undefined or return the fallback value*/
export const valueWithFallback = (value, fallback) => {
  if (value !== undefined) {
    return value
  } else {
    return fallback
  }
}
