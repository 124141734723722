class Money {
  constructor(private amount: number = 0, private currency: string) {}

  prettyPrint = () => {
    switch (this.currency) {
      case 'ZAR': {
        return `R${(this.amount / 100).toFixed(2)}`
      }
    }
  }
}

export default Money
